import * as Vue from 'vue'
import App from './App.vue'
import router from '@/router'
import userStore from '@/store/user'

import { Amplify } from 'aws-amplify'
import amplify_config from '@/amplify_config'

Amplify.configure(amplify_config)


import { I18n } from 'aws-amplify'
import { createI18n } from 'vue-i18n'
import { translations } from '@aws-amplify/ui-vue'
import { getStartingLocale, loadLocaleMessages } from '@/utils/locale'

I18n.putVocabularies(translations)
I18n.setLanguage(getStartingLocale())
const i18n = createI18n({
    locale: getStartingLocale(),
    fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || 'es',
    allowComposition: true,
    messages: loadLocaleMessages(),
})

import titleMixin from '@/mixins/titleMixin'


import 'bootstrap'
import 'bootstrap/dist/css/bootstrap.min.css'
import '@aws-amplify/ui-vue/styles.css'


Vue.createApp(App)
    .use(router)
    .use(userStore)
    .use(i18n)
    .mixin(titleMixin)
    .mount('#app')
