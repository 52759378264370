import { createRouter, createWebHistory } from 'vue-router'
import { Hub } from "aws-amplify";
import store from '../store/user.js'
import { refreshAndGetUser } from "@/utils/cognito";

Hub.listen('auth', async ({ payload }) => {
  const next = router.currentRoute.value.query.nextUrl

  switch (payload.event) {
    case 'signIn':
      await refreshAndGetUser()
      await router.push({path: next ? next : '/application'})
      break
    case 'signOut':
      store.commit('setUser', null)
      await router.push({path: '/login', query: { nextUrl: router.currentRoute.value.fullPath }})
      break
  }
})

const routes = [
  {
    path: '/',
    name: 'home',
    meta: { requiresAuth: true },
    component: () => import(/* webpackChunkName: "" */ '../views/OdontogramView.vue')
  },
  {
    path: '/login',
    name: 'login',
    component: () => import(/* webpackChunkName: "login" */ '../views/AuthView.vue')
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeResolve(async (to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    const user = await refreshAndGetUser()
    if (!user) {
      return next({
        path: '/login',
        query: {
          nextUrl: to.fullPath,
        }
      })
    }
  }
  return next()
});

export default router
