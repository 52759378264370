<template>
  <div>
    <nav class="navbar navbar-expand-lg navbar-light bg-light d-flex py-4 px-lg-5" ref="header">
      <div class="container-fluid">
        <a class="navbar-brand logo" href="/"><img :src="require('@/assets/img/logo.svg')" alt="Logo"/>HS One Advanced AI Research Lab</a>
        <ul class="navbar-nav m-auto">
          <li class="nav-item fw-bold">
            Clínica byTeeth
          </li>
        </ul>
        <a class="btn btn-primary" href="https://www.demo-periapicales.totia.es/">Análisis de radiografías panorámicas</a>
      </div>
    </nav>

    <div class="patient-info row d-flex py-4 px-5">
      <div class="d-flex flex-row align-items-center">
        <div class="d-inline patient-name pe-2">
          Abád Sanchez, <span class="fw-bold">Javier</span>
        </div>
        <div class="patient-info-text d-inline-block ps-2" style="border-left: 1px solid #003082">
          <div>43 Años · Historial: 1014</div>
          <div>Tarifa: </div>

        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {Auth} from "aws-amplify";

export default {
  name: 'NavBar',
  props: {
    type: String,
    variant: String,
  },
  components: {},
  data: () => ({
  }),
  methods: {
    async logout () {
      Auth.signOut().catch((e) => {
        console.log(e)
      })
    },
    has_role(role) {
      return this.isLoggedIn && role === role
    },
    getLinkClass(link_href) {
      let css_class = "nav-link btn"
      if(this.$router.currentRoute.value.fullPath === link_href)
        css_class = css_class + " active"
      return css_class
    }
  },
  computed: {
    isLoggedIn() {
      return this.$store.getters.isLoggedIn
    },
    user() {
      return this.$store.getters.user
    }
  }
}
</script>

<style scoped>
.navbar {
  height: 70px;
  font-size: 18px;
  color: white;
}

.bg-light {
  background-color: #003082 !important;
}

.navbar-brand {
  font-size: 24px;
  font-weight: bold;
  color: white !important;
}

.logo img{
  height: 50px;
}

.patient-name {
  color: #003082;
  font-size: 24px;
}

.patient-info-text {
  color: #003082;
  font-size: 16px;
}

.patient-info {
  background-color: #e6ecf7;
}
</style>
