import {Auth} from "aws-amplify";
import store from "@/store/user";

export async function refreshAndGetUser() {
    return Auth.currentAuthenticatedUser().then((data) => {
        if (data && data.signInUserSession) {
            store.commit('setUser', data)
            return data
        }
    }).catch(() => {
        store.commit('setUser', null)
        return null
    });
}
